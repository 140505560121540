import "./set-public-path";
import { stringify, parse, ISerializable } from "./searchParams";
const SearchParams = {
  parse,
  stringify,
};

import { createBrowserHistory } from "history";
import { History } from "./history-types";
const history: History<Record<string, unknown>> = createBrowserHistory();

export { SearchParams, history, ISerializable };

export * from "./history-types";

/**
 * History version 5
 */
// import _history from "history/browser";
// import type { History } from "history";
//
// export const history: History = _history;
//
// export type {
//   History,
//   BrowserHistory,
//   BrowserHistoryOptions,
//   Location,
//   Listener,
//   State,
//   Path,
//   Search,
//   Key,
//   PartialPath,
//   PartialLocation,
//   Update,
//   Transition,
//   Blocker,
//   To,
//   InitialEntry,
// } from "history";
