type IPrimitive = undefined | null | boolean | number | string;
export interface ISerializable {
  [k: string]: IPrimitive | Array<IPrimitive> | ISerializable;
}

export const stringify = (
  obj: ISerializable | Partial<ISerializable>,
): string => {
  const params = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]) => {
    try {
      params.append(
        encodeURIComponent(key.toString()),
        encodeURIComponent(JSON.stringify(value)),
      );
    } catch (e) {
      console.error("Could not stringify query parameter", key, value);
      console.error(e);
    }
  });
  params.sort();
  return params.toString();
};

export const parse = (str: string): ISerializable => {
  const params = new URLSearchParams(str);
  const obj: ISerializable = {};
  params.forEach((value, key) => {
    try {
      obj[decodeURIComponent(key)] = JSON.parse(decodeURIComponent(value));
    } catch (e) {
      console.error("Could not parse query parameter", key, value);
      console.error(e);
    }
  });
  return obj;
};
